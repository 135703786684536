import { Stripe, StripeCardNumberElement } from "@stripe/stripe-js";
import OrdersAPI from "../../API/ordersAPI";
import UserAPI from "../../API/userAPI";
import { setCustomer } from "../customer/customerSlice";
import { Dispatch } from "@reduxjs/toolkit";
import { AuthResponse } from "../../API/userAPI";
import { setAuthInfo } from "../user/userSlice";
import { PaymentData, DispatchDataResponse } from "react-acceptjs";
import AuthNetAPI from "../../API/authNetAPI";
import CustomerObj from "../customer/customerObj";
import AffilitateAPI from "../../API/affiliateAPI";
import { IReferralPost } from "../referrals/interfaces";
import TiktokPixel from "tiktok-pixel";
import { FormValues } from "../../components/checkout-form/interfaces";

export default class CheckoutUtils {
  static async getAuthNetNonce(
    cardNumber: string,
    cardCvc: string,
    cardExpiry: string,
    authorizeNet: {
      dispatchData: (paymentData: PaymentData) => Promise<DispatchDataResponse>;
      loading: boolean;
      error: boolean;
    },
    context: Record<string, any>
  ) {
    let response;

    try {
      response = await authorizeNet.dispatchData({
        cardData: {
          cardNumber: cardNumber.replace(/\s/g, ''),
          cardCode: cardCvc,
          month: cardExpiry.replace(/\D/g, '').substring(0, 2),
          year: cardExpiry.replace(/\D/g, '').substring(2, 4)
        }
      });
    } catch (e: any) {
      throw new Error(e.messages.message[0].text);
    }

    if (!response) throw new Error("Unable to authorize card. Please try later.");

    if (response.messages.resultCode !== "Ok") {
      throw new Error(response.messages.message[0].text);
    }

    context.authNonce = response.opaqueData.dataValue;

    return response
  }

  static async getAuthNetNonceApplePay(
    paymentData: string,
    authorizeNet: {
      dispatchData: (paymentData: PaymentData) => Promise<DispatchDataResponse>;
      loading: boolean;
      error: boolean;
    }
  ): Promise<DispatchDataResponse> {
    let response;

    try {
      const tokenizedData: any = {
        authData: {
          clientKey: process.env.REACT_APP_STAGE_AUTHORIZE_NET_PUBLIC_CLIENT_KEY,
          apiLoginID: process.env.REACT_APP_STAGE_AUTHORIZE_NET_API_LOGIN_ID,
        },
        opaqueData: {
          dataDescriptor: 'COMMON.APPLE.PAY',
          dataValue: paymentData,
        },
      };

      alert("Tokenized Data: " + JSON.stringify(tokenizedData)); // Alert the tokenized data for debugging
      response = await authorizeNet.dispatchData(tokenizedData as any);
    } catch (e: any) {
      alert("Error during dispatching data: " + (e?.messages?.message?.[0]?.text || "Error processing Apple Pay payment."));
      throw new Error(e?.messages?.message?.[0]?.text || "Error processing Apple Pay payment.");
    }

    if (!response || response.messages.resultCode !== "Ok") {
      alert("Invalid response from Authorize.Net: " + JSON.stringify(response));
      throw new Error(response?.messages?.message[0]?.text || "Unable to authorize Apple Pay payment. Please try again later.");
    }

    return response;
  }

  static async processAuthNetPayment(values: FormValues, context: Record<string, any>, handleAuthSavePayment: (values: FormValues, context: Record<string, any>) => void) {
    try {
      const response = await AuthNetAPI.capturePayment(context.token, context.authNonce, context.order);
      const { transId } = response.transactionResponse;
      context.authNetTransId = transId;
      const isPaymentSuccessful = transId && Number(transId) !== 0;
      const isAVSMismatchError = isPaymentSuccessful && response.messages.message[0].code === 'E00027' &&
        response.messages.message[0].text === 'The transaction was unsuccessful.';

      if (!isAVSMismatchError && (response.messages.resultCode !== 'Ok' || response.transactionResponse.responseCode !== '1')) {
        if (response.messages.resultCode !== "Ok") {
          OrdersAPI.updateOrder(context.token, context.order.data.id, { status: 'failed' });
          let orderNote = "Authorize.net transaction failed with CODE " +
            response.messages.message[0].code + ": " + response.messages.message[0].text
          OrdersAPI.createOrderNote(context.token, context.order.data.id, orderNote);
          console.error("Authorize.net transaction failed. ", response);
          throw new Error("Credit card transaction could not be completed.");
        } else if (response.transactionResponse.responseCode !== '1') {
          OrdersAPI.updateOrder(context.token, context.order.data.id, { status: 'failed' });
          OrdersAPI.createOrderNote(context.token, context.order.data.id,
            this.#getAuthNetOrderNote(response.transactionResponse));
          throw new Error(response.transactionResponse.errors[0].errorText);
        }
      }

      // Track payment information using TiktokPixel
      TiktokPixel.track('AddPaymentInfo', {
        payment_method: {
          authNetTransId: transId,
          billing_details: {
            address: {
              line1: context.order.data.billing.address_1,
              line2: context.order.data.billing.address_2,
              city: context.order.data.billing.city,
              state: context.order.data.billing.state,
              postal_code: context.order.data.billing.postcode,
              country: 'US',
            },
            email: context.order.data.billing.email,
            name: `${context.order.data.billing.first_name} ${context.order.data.billing.last_name}`,
          },
        },
      });

      // Create order note for successful charge
      OrdersAPI.createOrderNote(context.token, context.order.data.id, `Authorize.net charge complete (ID: ${transId})`);
      handleAuthSavePayment(values, context);
    } catch (error) {
      // Handle any unexpected errors
      console.error('An error occurred during payment processing:', error);
      OrdersAPI.createOrderNote(context.token, context.order.data.id, `An error occurred during payment processing: ${error}`);
      throw new Error(`An error occurred during payment processing: ${error}`);
    }
  }

  static async processAuthNetSavedPayment(context: Record<string, any>, getSavedCardData: any) {
    try {
      const response = await AuthNetAPI.savedPaymentTransaction(context.order.data.total, getSavedCardData?.authorize_customer_id, getSavedCardData?.authorize_card_profile_id);
      const { transId } = response.transactionResponse;
      context.authNetTransId = transId;
      const isPaymentSuccessful = transId && Number(transId) !== 0;
      const isAVSMismatchError = isPaymentSuccessful && response.messages.message[0].code === 'E00027' &&
        response.messages.message[0].text === 'The transaction was unsuccessful.';

      if (!isAVSMismatchError && (response.messages.resultCode !== 'Ok' || response.transactionResponse.responseCode !== '1')) {
        if (response.messages.resultCode !== "Ok") {
          OrdersAPI.updateOrder(context.token, context.order.data.id, { status: 'failed' });
          let orderNote = "Authorize.net transaction failed with CODE " +
            response.messages.message[0].code + ": " + response.messages.message[0].text
          OrdersAPI.createOrderNote(context.token, context.order.data.id, orderNote);
          console.error("Authorize.net transaction failed. ", response);
          throw new Error("Credit card transaction could not be completed.");
        } else if (response.transactionResponse.responseCode !== '1') {
          OrdersAPI.updateOrder(context.token, context.order.data.id, { status: 'failed' });
          OrdersAPI.createOrderNote(context.token, context.order.data.id,
            this.#getAuthNetOrderNote(response.transactionResponse));
          throw new Error(response.transactionResponse.errors[0].errorText);
        }
      }

      // Track payment information using TiktokPixel
      TiktokPixel.track('AddPaymentInfo', {
        payment_method: {
          authNetTransId: transId,
          billing_details: {
            address: {
              line1: context.order.data.billing.address_1,
              line2: context.order.data.billing.address_2,
              city: context.order.data.billing.city,
              state: context.order.data.billing.state,
              postal_code: context.order.data.billing.postcode,
              country: 'US',
            },
            email: context.order.data.billing.email,
            name: `${context.order.data.billing.first_name} ${context.order.data.billing.last_name}`,
          },
        },
      });

      // Create order note for successful charge
      OrdersAPI.createOrderNote(context.token, context.order.data.id, `Authorize.net charge complete (ID: ${transId})`);
    } catch (error) {
      // Handle any unexpected errors
      console.error('An error occurred during payment processing:', error);
      OrdersAPI.createOrderNote(context.token, context.order.data.id, `An error occurred during payment processing: ${error}`);
      throw new Error(`An error occurred during payment processing: ${error}`);
    }
  }

  static maybePostReferral(
    customer: CustomerObj,
    context: Record<string, any>,
    lifeTimeAffiliate: Record<string, any>,
    affiliateVisitorID?: string | undefined,
  ) {
    if (!('affiliate_id' in context.affiliateData) && !(lifeTimeAffiliate.affiliate_id)) return;

    const affiliateRate = parseFloat(context.affiliateData?.rate) || parseFloat(lifeTimeAffiliate?.rate) || 10;

    let data: IReferralPost = {
      affiliateId: context.affiliateData.affiliate_id || lifeTimeAffiliate.affiliate_id,
      affiliateRate: affiliateRate,
      description: context.order.getProductsString(),
      reference: context.order.data.id,
      customerId: context.customerId
    }

    if ('visit_id' in context.referralVisitData) {
      data.visitId = context.referralVisitData.visit_id;
    }

    AffilitateAPI.postReferral(data).then((response: any) => {
      const affiliateVisitorIDNumber = affiliateVisitorID ? parseFloat(affiliateVisitorID) : 0;
      AffilitateAPI.UpdateRefferal(response.referral_id, data.customerId, affiliateVisitorIDNumber);
      AffilitateAPI.postLifetimeAffiliate(data.affiliateId, data.customerId);
    });
  }

  static async processStripePayment(
    stripe: Stripe,
    cardElement: StripeCardNumberElement,
    context: Record<string, any>
  ) {
    return Promise.resolve().then(() => {
      const paymentIntent = context.order.getStripePaymentIntent();

      if (!paymentIntent || !context.order.isChargeable()) return null;

      return stripe.confirmCardPayment(
        paymentIntent.client_secret,
        {
          payment_method: {
            card: cardElement,
            billing_details: {
              address: {
                line1: context.order.data.billing.address_1,
                line2: context.order.data.billing.address_2,
                city: context.order.data.billing.city,
                state: context.order.data.billing.state,
                postal_code: context.order.data.billing.postcode,
                country: 'US'
              },
              email: context.order.data.billing.email,
              name: context.order.data.billing.first_name + ' ' + context.order.data.billing.last_name
            }
          }
        }
      );
    }).then((payment) => {
      if (payment && payment.error) {
        if (context.order.data.status !== 'failed') {
          OrdersAPI.updateOrder(context.token, context.order.data.id, { status: 'failed' });
        }

        let orderNote = 'The card failed with ERROR CODE: ' + payment.error.code;

        if (payment.error.decline_code && payment.error.decline_code.length) {
          orderNote += ', DECLINE CODE: ' + payment.error.decline_code;
        }
        OrdersAPI.createOrderNote(context.token, context.order.data.id, orderNote);
        throw new Error(payment.error.message);
      }

      TiktokPixel.track(
        'AddPaymentInfo',
        {
          payment_method: {
            card: cardElement,
            billing_details: {
              address: {
                line1: context.order.data.billing.address_1,
                line2: context.order.data.billing.address_2,
                city: context.order.data.billing.city,
                state: context.order.data.billing.state,
                postal_code: context.order.data.billing.postcode,
                country: 'US'
              },
              email: context.order.data.billing.email,
              name: context.order.data.billing.first_name + ' ' + context.order.data.billing.last_name
            }
          }
        }
      );

      // If payment is null, then there was no CC charge needed for this order.
      if (payment && payment.paymentIntent && payment.paymentIntent.status === "succeeded") {
        OrdersAPI.createOrderNote(context.token, context.order.data.id, 'Stripe charge complete(ID: ' +
          payment.paymentIntent.id + ')');
      }
    });
  }

  static async registerAndAuthenticate(
    email: string,
    password: string,
    dispatch: Dispatch<any>,
    context: Record<string, any>
  ) {
    return Promise.resolve().then(() => {
      let metaData = [];
      if ('affiliate_id' in context.affiliateData) {
        metaData.push({
          key: "_mm_referring_affiliate_id",
          value: context.affiliateData.affiliate_id
        })
      }
      return UserAPI.registerUser(email, password, metaData);
    }).then((response) => {
      if ('code' in response) {
        if (response.data && response.data.status === 400) {
          throw new Error("That email address is already registered. Please log in.");
        } else {
          throw new Error(response.message);
        }
      }

      context.customerId = response.id;
      dispatch(setCustomer(response));

      return UserAPI.authenticateUser(
        email,
        password,
        false
      );
    }).then((response: AuthResponse) => {
      if (response.data && response.data.status === 403) {
        throw new Error("Invalid email/password combination.");
      }

      dispatch(setAuthInfo({
        token: response.token,
        email: response.user_email,
        nicename: response.user_nicename,
        display_name: response.user_display_name
      }));

      context.token = response.token;
    });
  }

  // -------------------------------------
  // P R I V A T E   M E T H O D S
  // -------------------------------------

  static #getAuthNetAvsMismatchText = (avsCode: string) => {
    switch (avsCode) {
      case 'A':
        return 'A (Zip code mismatch)';
      case 'B':
        return 'B (No address provided)';
      case 'E':
        return 'E (AVS check error)';
      case 'G':
        return 'G (Non US bank / no AVS support)';
      case 'N':
        return 'N (street and zip code mismatch)';
      case 'P':
        return 'P (AVS not applicable to this transaction)';
      case 'R':
        return 'R (Retry - AVS unavailable)';
      case 'S':
        return 'S (AVS not supported by card issuer)';
      case 'U':
        return 'U (Address information unavailable)';
      case 'W':
      case 'Z':
        return avsCode + ' (Street address mismatch)';
      case 'X':
        return 'X (Street and ip code mismatch)';
      case 'Y':
        return 'Y (Street and zip code match)';
    }

    return avsCode + ' (Unknown)';
  }

  static #getAuthNetCvvMismatchText = (cvvCode: string) => {
    switch (cvvCode) {
      case 'M':
        return 'M (CVV matched)';
      case 'N':
        return 'N (CVV mismatch)';
      case 'P':
        return 'P (CVV was not processed)';
      case 'S':
        return 'S (CVV was not indicated)';
      case 'U':
        return 'U (Issuer was unable to process the CVV check)';
    }

    return cvvCode + ' (Unknown)';
  }

  static #getAuthNetOrderNote = (transResponse: Record<string, any>) => {
    let orderNote = 'The card failed with RESPONSE CODE: ' +
      transResponse.responseCode + this.#getAuthNetResponseCodeText(transResponse.responseCode);

    if (transResponse.avsResultCode && !['Y', 'P'].includes(transResponse.avsResultCode)) {
      orderNote += ", AVS MISMATCH CODE: " +
        this.#getAuthNetAvsMismatchText(transResponse.avsResultCode);
    }

    if (transResponse.cvvResultCode && transResponse.cvvResultCode !== 'M') {
      orderNote += ", CVV MISMATCH CODE: " +
        this.#getAuthNetCvvMismatchText(transResponse.cvvResultCode);
    }

    return orderNote;
  }

  static #getAuthNetResponseCodeText = (responseCode: string) => {
    switch (responseCode) {
      case '1':
        return "1  (Approved)";
      case '2':
        return "2 (Declined)";
      case '3':
        return "3 (Error)";
      case '4':
        return "4 (Held for Review)";
    }
    return responseCode + " (Unknown)";
  }
}
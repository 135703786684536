import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom';
import metaData from './metaData';
import { useSelector } from 'react-redux';
import { selectToken } from '../features/user/userSlice';

export default function MetaPage() {
    const location = useLocation();
    const { pathname } = location;
    const token = useSelector(selectToken);
    const normalizedPath = pathname.split('?')[0].replace(/\/$/, '');
    const isLoginPage = location.state && location.state.Tabkey === 'login';
    const isRegisterPage = location.state && location.state.Tabkey === 'register';
    const currentMeta = !token ? (isLoginPage ? metaData['/my-account-login'] :
        isRegisterPage ? metaData['/my-account-register'] : metaData['/']) :
        location.pathname.includes('/my-account') ? metaData['/'] : metaData[normalizedPath] || metaData['/'];

    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{currentMeta.title} - Chef-Prepared Healthy Meals Delivered Fresh To You!</title>
            <meta property="og:title" content={currentMeta.title} />
            <meta name="description" content={currentMeta.description} />
            <meta property="og:description" content={currentMeta.description} />
            <meta property="og:image" content={currentMeta.image} />
            <meta property="og:image:width" content="2560" />
            <meta property="og:image:height" content="1709" />
        </Helmet>
    )
}
